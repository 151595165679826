<!--
 * @Author: your name
 * @Date: 2020-11-24 12:17:18
 * @LastEditTime: 2021-05-10 09:54:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\adminInverter.vue
-->
<template>
    <div class="adminInverter-main-box">
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{ $t('Regional_country_selection') }}</span>
                <el-select v-model="search.type">
                    <el-option v-for="item in RegionalcountryArr.AREA_TYPES" :key="item.id" :label="item.code"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <div class="search-box-sub">
                <span class="lable-text">{{ $t('area_selection') }}</span>
                <el-select filterable @change="getAllCountry" v-model="search.regionID">
                    <el-option v-for="item in areaArr" :key="item.regionID" :label="item.regionNameInternational"
                        :value="item.regionID">
                    </el-option>
                </el-select>
            </div>

            <div class="search-box-sub">
                <span class="lable-text">{{ $t('nation_selection') }}</span>
                <el-select filterable v-model="search.countryID">
                    <el-option v-for="item in nationArr" :key="item.countryID" :label="item.countryNameInternational"
                        :value="item.countryID">
                    </el-option>
                </el-select>
            </div>


            <div class="search-box-sub">
                <span class="lable-text">{{ $t('Equipment_classification') }}</span>
                <el-select filterable v-model="search.invID">
                    <el-option v-for="item in EquipmentArr" :key="item.invID" :label="item.name" :value="item.invID">
                    </el-option>
                </el-select>
            </div>

            <div class="search-box-sub">
                <el-button type="primary" @click='query'>{{ $t('common.button.query') }}</el-button>
            </div>
        </div>
        <div class="button-box">
            <el-button type="primary" class="marginR10" icon='el-icon-plus'
                @click="() => { $router.push({ path: '/addrecommend' }) }">{{ $t('common.msg.submit') }}</el-button>
            <el-popconfirm :title="$t('common.button.del-bath-confirm')" @confirm='tbatteryDeleteBatch()'>
                <el-button slot="reference" icon='el-icon-delete' class="marginR10">{{ $t('link.delete') }}</el-button>
            </el-popconfirm>
            <!-- <el-button type="primary" icon='el-icon-upload2' class="pull-right" @click="dialogVisible = true">{{$t('system.import')}}</el-button> -->
        </div>
        <!-- :data="dataRows" -->
        <baseTable v-loading="loading" row-key='sid' border stripe :data="dataRows" :column='column' :select='true'
            @selection-change="handleSelectionChange($event)">
            <template #OperatingVoltageRange="{ data }">
                {{ data.operatingVoltageFrom }}-{{ data.operatingVoltageTo }}
            </template>

            <template #name='{ data }'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data.id)'>{{ data.name }}</a>
            </template>
            <template #option="{ data }">
                <div class="option-box">
                    <el-popconfirm :title="$t('common.button.del-bath-confirm')" @confirm='tbatteryDelete(data.id)'>
                        <i class="el-icon-delete" slot="reference"></i>
                    </el-popconfirm>
                </div>
            </template>
        </baseTable>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
            :page-sizes="[10, 20, 30, 50]" :page-size="size" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        <!--导入弹出框-->
        <el-dialog :title="$t('system.city.import')" :visible.sync="dialogVisible" width="30%" v-if='dialogVisible'>
            <el-button type="text" @click="download">
                {{ $t('system.template.upload') }}
            </el-button>
            <base-file v-model="uploadFile"></base-file>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{ $t('system.button.cancel') }}</el-button>
                <el-button type="primary" :loading="loadingBtn" @click="submitFile">{{ $t('common.button.ok') }}</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
import baseFile from '../../components/common/baseFileInputNew'
export default {
    components: {
        baseTable,
        baseFile
    },
    props: {

    },
    data() {
        return {
            search: {
                type: "",
                regionID: "",
                countryID: "",
                invID: "",
            },
            dialogVisible: false,
            batteryType: '',


            batteryIDs: [],
            loadingBtn: false,
            loading: false,
            uploadFile: '',
            batteryId: "",
            batteryDataList: [],
            dataRows: [],
            column: [
                {
                    title: this.$t('area_selection'),
                    value: 'region',
                    slot: 'region'
                },
                {
                    title: this.$t('nation_selection'),
                    value: 'country',
                    slot: 'country'
                },
                {
                    title: this.$t('equipment_powerstation'),
                    value: 'name',
                    slot: 'name'
                },
                {
                    title: this.$t('equipment_id'),
                    value: 'invID',
                    slot: 'invID'
                },
                //  {
                //     title: this.$t('common.msg.opeation'),
                //     value: 'option',
                //     slot: 'option'
                // }
            ],
            size: 10,
            current: 1,
            total: 1,
            RegionalcountryArr: {},
            areaArr: [],
            nationArr: [],
            EquipmentArr: [],
            RegionalcountryCode: '',
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.tbatteryGetPage();
        this.tbatteryListAll();
        this.getAllRegion()
        this.getAllCountry()
        this.tdictionaryGetDictionaryBatch()
        this.queryModellist()
    },
    methods: {



        // 获取字典
        async tdictionaryGetDictionaryBatch() {
            let res = await this.API.tdictionaryGetDictionaryBatch({
                lang: localStorage.getItem("lan"),
                dictCodes: [
                    "AREA_TYPES",
                ],
            });
            this.RegionalcountryArr = res;
        },

        /*获取所有区域*/
        async getAllRegion() {
            let res = await this.API.systemRegionsGetAll({})
            this.areaArr = res;
        },

        /*获取所有国家*/
        async getAllCountry() {
            let res = await this.API.systemCountryGetAll({
                regionId: this.search.regionId
            })
            this.nationArr = res
        },

        // 获取所有的机型列表
        async queryModellist() {
            let res = await this.API.queryModellist()
            this.EquipmentArr = res
        },


        /*分页查询推荐机型*/
        async tbatteryGetPage() {
            this.loading = true
            let oparam = {
                current: this.current,
                size: this.size,
            }

            for (let key in this.search) {
                if (this.search[key] !== "" && this.search[key] !== null) {
                    oparam[key] = this.search[key]
                }
            }

            console.log(oparam, '传参');

            let res = await this.API.queryRecommendedmodel(oparam)
            console.log(res);
            this.dataRows = res.rows
            this.total = parseInt(res.total)
            this.loading = false
        },










        /*导入模板下载*/
        download() {
            window.open(window.location.href.split('#')[0] + 'static/battery.xls')
        },


        /*提交文件*/
        async submitFile() {
            if (!this.uploadFile) {
                this.$message({
                    message: this.$t('remind.upload-file-first'),
                    type: 'warning'
                });
                return
            }
            this.loadingBtn = true
            let res = await this.API.tbatteryImportTInverters({
                file: this.uploadFile
            })
            this.loadingBtn = false
            if (res.success) {
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.dialogVisible = false
                this.uploadFile = ''
                this.tbatteryGetPage()
            } else {
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },

        // 获取电池所有数据
        async tbatteryListAll() {
            let res = await this.API.tbatteryListAll()
            this.batteryDataList = res
        },

        /*删除*/
        async tbatteryDelete(id) {
            this.batteryIDs = []
            this.batteryIDs.push(id)
            this.tbatteryDeleteBatch()
        },

        /*批量删除*/
        async tbatteryDeleteBatch() {
            let res = await this.API.deletemodels({
                iDs: this.batteryIDs
            })
            if (res.success) {
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.tbatteryGetPage()
            } else {
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        /*跳转详情页面*/
        pushEditUrl(id) {
            this.$router.push({ path: '/addBattery', query: { id: id } });
        },


        async query() {
            this.current = 1
            this.tbatteryGetPage()
        },

        handleSelectionChange(val) {
            this.batteryIDs = val.map(item => {
                return item.id
            })
        },

        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.tbatteryGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.tbatteryGetPage()
        },
    },
};
</script>

<style  lang="less" scoped></style>
